function initMap() {
    var mapElement = document.getElementById('map'); // Get the HTML DOM element that will contain your map - We are using a div with id="map" seen below in the <body>
    if (typeof mapElement !== 'undefined' && mapElement !== null) {
          var map_title = document.getElementById('map').getAttribute('map-title');
          var latitude  = document.getElementById('map').getAttribute('data-lat');
          var longitude = document.getElementById('map').getAttribute('data-lng');

          var mapOptions = {
              zoom: 17, // How zoomed in you want the map to start at (always required)
              scrollwheel: false,
              mapTypeId: 'roadmap',
              center: new google.maps.LatLng( latitude, longitude ), // The latitude and longitude to center the map (always required)
              // How you would like to style the map. This is where you would paste any style found on Snazzy Maps.
              styles:
              [
        {
            "featureType": "all",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "gamma": 0.5
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "administrative.country",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "administrative.country",
            "elementType": "labels.text",
            "stylers": [
                {
                    "color": "#e90303"
                }
            ]
        }
    ]
            };
          var map    = new google.maps.Map( mapElement, mapOptions ); // Create the Google Map using our element and options defined above
          var marker = new google.maps.Marker( // Let's also add a marker while we're at it
              {
                  position: new google.maps.LatLng( latitude, longitude ),
                  map: map,
                  title: map_title
              }
          );
   }
}
