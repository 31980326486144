jQuery(document).ready(function() {
    // Services within
    jQuery(".block-service-within .col-txt h3 a").mouseover( function () {
          imgZoom(this, true);
          btnOnOff ( jQuery(this).closest(".row").find('.col-txt .primary-button'), true );
    });
    jQuery(".block-service-within .col-txt h3 a").mouseout( function () {
          imgZoom(this, false);
          btnOnOff ( jQuery(this).closest(".row").find('.col-txt .primary-button'), false );
    });
    jQuery(".block-service-within .primary-button").mouseover( function () {
          linkOnOff( jQuery(this).prev().prev('h3').children('a'), true );
          imgZoom(this, true);
    });
    jQuery(".block-service-within .primary-button").mouseout( function () {
          linkOnOff( jQuery(this).prev().prev('h3').children('a'), false);
          imgZoom(this, false);
    });
    // Works
    jQuery(".section-oneofus .col-txt h3 a").mouseover( function () {
         imgZoom(this, true);
         btnOnOff ( jQuery(this).closest(".row").find('.col-txt .primary-button'), true );
    });
    jQuery(".section-oneofus .col-txt h3 a").mouseout( function () {
       imgZoom(this, false);
       btnOnOff ( jQuery(this).closest(".row").find('.col-txt .primary-button'), false );
    });
    jQuery(".section-oneofus .primary-button").mouseover( function () {
          linkOnOff( jQuery(this).prev().prev('h3').children('a'), true );
          imgZoom(this, true);
    });
    jQuery(".section-oneofus .primary-button").mouseout( function () {
          linkOnOff( jQuery(this).prev().prev('h3').children('a'), false);
          imgZoom(this, false);
    });
    // Cases
    jQuery(".block-cases .col-txt h3 a").mouseover( function () {
         imgZoom(this, true);
         btnOnOff ( jQuery(this).closest(".row").find('.col-txt .primary-button'), true );
    });
    jQuery(".block-cases .col-txt h3 a").mouseout( function () {
       imgZoom(this, false);
       btnOnOff ( jQuery(this).closest(".row").find('.col-txt .primary-button'), false );
    });
    jQuery(".block-cases .primary-button").mouseover( function () {
          linkOnOff( jQuery(this).prev().prev('h3').children('a'), true );
          imgZoom(this, true);
    });
    jQuery(".block-cases .primary-button").mouseout( function () {
          linkOnOff( jQuery(this).prev().prev('h3').children('a'), false);
          imgZoom(this, false);
    });
    // Image - :hover
    jQuery(".img-wrapper").mouseover( function () {
          linkOnOff( jQuery(this).closest(".row").find('.col-txt h3 a'), true);
          btnOnOff ( jQuery(this).closest(".row").find('.col-txt .primary-button'), true);
          imgZoom(this, true);
    });
    jQuery(".img-wrapper").mouseout( function () {
          linkOnOff( jQuery(this).closest(".row").find('.col-txt h3 a'), false);
          btnOnOff ( jQuery(this).closest(".row").find('.col-txt .primary-button'), false);
          imgZoom(this, false);
    });

});
// functions
function btnOnOff ( obj, onOff ) {
    if ( onOff ) { jQuery(obj).addClass('button-hover'); } else { jQuery(obj).removeClass('button-hover'); }
}
function imgZoom( thisObj , onOff ) {
     obj = jQuery( thisObj ).closest(".row").find('.zoom-effect-container .image-card img');
     if ( onOff ) { jQuery(obj).css('transform','scale(1.08)');
     } else { jQuery(obj).css('transform','scale(1)'); }
}
function linkOnOff( obj, onOff ) {
     if ( onOff ) { jQuery(obj).addClass('hover-on'); } else { jQuery(obj).removeClass('hover-on'); }
}
