function createPlayer( loadYouTubeVideoID, holderID ) {
  var player;
  return new YT.Player( holderID, {
      videoId: loadYouTubeVideoID, // YouTube Video ID
      width: 100,               // Player width (in px)
      height: 100,              // Player height (in px)
      playerVars: {
        playlist: loadYouTubeVideoID,
          autoplay: 1,        // Auto-play the video on load
          disablekb: 1,
          controls: 0,        // Show pause/play buttons in player
          showinfo: 0,        // Hide the video title
          modestbranding: 1,  // Hide the Youtube Logo
          loop: 1,            // Run the video in a loop
          fs: 0,              // Hide the full screen button
          autohide: 0,         // Hide video controls when playing
          rel: 0,
          enablejsapi: 1
      },
      events: {
        onReady: function(e) {
            e.target.mute();
            e.target.setPlaybackQuality('hd1080');
        },
        onStateChange: function(e) {
          if(e && e.data === 1){
              var videoHolder = document.getElementById( holderID );
              if(videoHolder && videoHolder.id){
                videoHolder.classList.remove('home-banner-box');
              }
          }else if(e && e.data === 0){
            e.target.playVideo();
            // console.log('start video');
          }
        }
      }
  });

}
