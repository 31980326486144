jQuery(document).ready(function() {
    jQuery( "#menu_bar" ).click(function( event ) {
        event.preventDefault();
        jQuery('body').toggleClass('menu-responsive-open');
        if ( jQuery( '#menu_bar span').hasClass( 'menu-icon') ) {
              jQuery ( '#menu_bar span').css('transform', 'rotate(90deg)');
              jQuery ( 'body' ).css({ 'top' : '0','position' : 'fixed','width' : '100%' });
              jQuery ( '#body_overlay').css({ 'visibility' : 'visible','opacity' : '0.3' });
              jQuery ( '#site_main').css('transform','matrix(1, 0, 0, 1, -283, 0)');
              jQuery ( "#menu_reponsive").css({'transform' : 'matrix(1, 0, 0, 1, 0, 0)','width'   : '283px'});
        } else {
              jQuery ( '#menu_bar span').css('transform', 'rotate(0deg)');
              jQuery ( 'body' ).css({ 'top' : '0','position' : 'relative','width' : '100%'});
              jQuery ( '#body_overlay').css( { 'opacity' : '0','visibility' : 'hidden' });
              jQuery ( "#menu_reponsive").css( {'width' : '0px' } );
              jQuery ( '#site_main').css('transform','none');

              jQuery('#news_responsive_form').css("display","none");
              jQuery('#nav_responsiv').show();
              jQuery('.social-icons-responsive').css("display","flex");
              jQuery("#news_menu_responsiv").attr("style", "display: inline-block !important");


        }
         jQuery ( '#menu_bar span').toggleClass('menu-icon menu-cross');
    });

    jQuery('#body_overlay').on('click', function() {
          jQuery('body').toggleClass('menu-responsive-open');
          jQuery ( '#menu_bar span').css('transform', 'rotate(0deg)');
          jQuery ( 'body' ).css({ 'top' : '0','position' : 'relative','width' : '100%'});
          jQuery ( '#body_overlay').css( { 'opacity' : '0','visibility' : 'hidden' });
          jQuery ( "#menu_reponsive").css( {'width' : '0px' } );
          jQuery ( '#site_main').css('transform','none')    
          jQuery('#news_responsive_form').css("display","none");
          jQuery('#nav_responsiv').show();
          jQuery('.social-icons-responsive').css("display","flex");
          jQuery("#news_menu_responsiv").attr("style", "display: inline-block !important");
          jQuery ( '#menu_bar span').toggleClass('menu-icon menu-cross');
    });

    jQuery( "#news_menu_responsiv" ).click(function( event ) { // button
          event.preventDefault();
          jQuery('#nav_responsiv').hide();
          jQuery('.social-icons-responsive').hide();
          jQuery("#news_menu_responsiv").attr("style", "display: none !important");
          jQuery('#news_responsive_form').css("display","flex");
    });

});
