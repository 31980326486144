// require("block-faq.js");
// require("block-services-within.js");
// require("block-services.js");
// require("blog-select.js");
// require("form-service.js");
// require("header.js");
// require("init-gmap.js");
// require("main.js");
// require("newsletter.js");
// require("search.js");
// require("video-mp4.js");
// require("wtmg-dev.js");
// require("yt-api.js");

const jQuery = require('jquery')
window.$ = window.jQuery = jQuery

/* Import dependencies */
import 'bootstrap';
import 'jquery-validation';

/* Import js files */

import "./main.js";
import "./header.js";
import "./init-gmap.js";
import "./yt-api.js";
import "./video-mp4.js";
import "./block-faq.js";
import "./block-services-within.js";
import "./blog-select.js";
import "./block-services.js";
import "./form-service.js";
import "./newsletter.js";
import "./search.js";
import "./odometer.js";



/* Import form class */
import Form from './sign-up-form';
new Form();
