var html = document.documentElement;

html.classList.remove('no-js');
html.classList.add('js');

jQuery(document).ready(function() {

   // jQuery('.desc-txt').shave(99);
   // jQuery('.section-ittb-text').shave(99);
  // Animate on Scroll init
   AOS.init({
       once: true, // Choose wheter animation should fire once, or every time you scroll up/down to element
       mirror: false, // whether elements should animate out while scrolling past them
       animatedClassName: 'aos-animate odometer',
       // offset: 200,
       // duration: 600,
       // easing: 'ease-in-sine',
       // delay: 100,
   });

   $('[href^="#"]').on('click', function(e) {
       var $this = $(this);
       var $thisParentSection = $this.parents('section');
       var $thisParentSectionParent = $thisParentSection.parent().attr('id');

       if ( $thisParentSectionParent == 'site_main') {
           var $targetSection = $($(this).attr('href')).parents('section');

            $('html, body').animate({
                scrollTop: $targetSection.position().top
            }, 1000);

       }

   });

   if ($('.wpcf7').length) {
       $('.wpcf7-response-output').wrap('<div class="wpcf7-response-output-container"></div>');

       $('.wpcf7-response-output-container').on('click', function() {
            $('.wpcf7-form').removeClass('sent');
       });

   }

var navbar = document.getElementById("main-nav"); // Get the navbar
var header = document.getElementsByTagName("header");
var footer = document.getElementsByTagName("footer");
var logo = document.getElementById("logo-wrapper");
var news = document.getElementById("news_wrapper");
var push = document.getElementById("header-push");
var stickyNewsPos = news.offsetTop;
var stickyFooterPos= footer[0].offsetTop; // Get the offset position of the navbar

window.onscroll = function() { myFunction(); };

    function myFunction() {

        var footerTop = jQuery('#footer_main').offset().top;
        var socialTop = jQuery('#social_wrapper').offset().top;

        if ( socialTop >= footerTop ) {
            if ( !jQuery('#social_wrapper a span').hasClass('over-footer')) {
                 jQuery('#social_wrapper a span').addClass('over-footer');
            }
        } else {
            if ( jQuery('#social_wrapper a span').hasClass('over-footer')) {
                 jQuery('#social_wrapper a span').removeClass('over-footer');
            }
        }

        if ( window.innerWidth > 1000 ) {
              if (window.pageYOffset > 100 ) {
                header[0].classList.add("sticky-header");
                logo.classList.add("logo-left");
                push.classList.add("show");

              }
              if (window.pageYOffset < 100 ) {

                  header[0].classList.remove("sticky-header");
                  logo.classList.remove("logo-left");
                  push.classList.remove("show");
              }
        } else {

            if (window.pageYOffset > 1 ) {
                document.body.classList.add("sticky-responsive-header");
            } else {
                document.body.classList.remove("sticky-responsive-header");
            }

        }
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        var scrolled = (winScroll / height) * 100;
        document.getElementById("pgBar").style.width = scrolled + "%";

    }
});
