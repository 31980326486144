jQuery(document).ready(function() {

      jQuery('#span_news').click(function( event ) {

            jQuery('#news_wrapper').css("height", "400px");
            jQuery('#news_wrapper').css("width", "250px");
            jQuery('#span_news').addClass('open');
            jQuery('#close_news').show();
            jQuery('#form_wrapper').show();

      });

      jQuery('#close_news').click(function( event ) {

            jQuery('#news_wrapper').css({
                'height' : '100px',
                'width' : '30px'
            });

            jQuery('#span_news').removeClass('open');
             jQuery('#form_wrapper').hide();
            jQuery(this).hide();
      });

});
