class Form {

    constructor() {
        this.AddParticipents();
        this.NextPage();
        this.ValidatorSettings();
        this.InvoiceType();
        this.Coupon();

    }

    AddParticipents() {

        $(document).ready(function() {

            $('.wpcf7-field-group-add').on('click', (e) => {

                var $this = $(e.currentTarget);
                var $clone = $this.parents('.wpcf7-field-group').clone(true, true);
                var currentParticipants =  $('.wpcf7-field-group').length;
                var newParticipants = currentParticipants + 1;

                $clone.appendTo('#participants');

                $clone.children('p').each(function() {
                    var $this = $(this);

                    if (!$this.find('[name]').length) {
                        return;
                    }

                    var $form_element_name = $this.find('[name]').attr('name').replace('-' + currentParticipants, '-' + newParticipants);

                    if ($this.find('input').length > 1) {
                        $this.find('input').each(function(index, val) {

                            var $form_element_name_loop = $(val).attr('name').replace('-' + currentParticipants, '-' + newParticipants);

                            $(val).closest('.wpcf7-list-item').find('label').attr('for', $form_element_name_loop);
                            $(val).attr('id', $form_element_name_loop);
                            $(val).attr('name', $form_element_name_loop);
                            $(val).prop('checked', false);
                        });
                    } else {
                        $this.find('label').attr('for', $form_element_name);
                        $this.find('[id]').attr('id', $form_element_name);
                        $this.find('[name]').attr('name', $form_element_name);
                        $this.find('[name]').val('');
                    }

                });
            });

            $('.wpcf7-field-group-remove').on('click', (e) => {
                var $this = $(e.target);

                $this.parents('.wpcf7-field-group').remove();
            });

        })
    }

    NextPage() {

        $(document).ready(() => {
            let current = 0;
            nextTab(current);

            $('.next').click( (e) => {

                let next = current + 1;

                if ( next == $('.form-tab').length ) {
                    return;
                }

                if (next >= ($('.form-tab').length - 1)) {

                    let inputs = $(".wpcf7-form").find(':input').not('.not_included').serializeArray();

                    if (this.ValidateForm()) {

                        this.SubmitForm(inputs, (status) => {
                            console.log(status);

                            if ($('.cities').length) {
                                $('.cities').hide();
                            }

                            $($('.form-tab')[current]).hide();
                            nextTab(next);
                            nextStep(next);
                            current = current + 1
                        })

                    }

                    return;
                }

                if (this.ValidateForm()) {
                    $($('.form-tab')[current]).hide();
                    current = current + 1;
                    nextTab(next);
                    nextStep(next);
                    $('html, body').animate({scrollTop: $('html').offset().top}, 300);
                }


            });

            function nextTab(tabIndex) {

                $($('.form-tab')[tabIndex]).show();
            }

            function nextStep(tabIndex) {

                $($('.sign-up-step')[tabIndex]).addClass('is-current');
            }


        })
    }

    InvoiceType() {
        $(document).ready(function() {

            const labels = {
                "is-company": {
                    company: 'Företag',
                    orgnum: 'Organisationsnummer'
                },
                "is-individ": {
                    company: 'Namn',
                    orgnum: 'Personnummer'
                }
            }

            $('input[name=invoice-type]').on('click', (e) => {
                    let el = $(e.currentTarget);

                    $.each(labels[el.attr('id')], (id, val) => {
                        $('#'+id).attr("placeholder", val)
                    });

            });
        });
    }

    Coupon() {
        $(document).ready(() => {
            let isValidatorRunning = false;

            $('#activate-coupon').click(() => {

                if (!isValidatorRunning) {

                    $('#coupon').keyup((e) => {
                        this.ValidateCoupon();
                    })

                    isValidatorRunning = true;
                }

                if (!this.ValidateCoupon()) {
                    this.ApplyCoupon($('#coupon').val());
                }

            });

        });
    }

    ValidateCoupon(errorCode = null) {
        let errorElement = $('<label id="coupon-error" class="error" for="coupon-error"></label>')
        let errorText = 'Fältet får inte vara tomt.';
        let error = false;
        let couponCode = $('#coupon');

        if (couponCode.val() != '' ) {
            error = false;

            if (errorCode) {
                errorText = errorCode;
                error = true;
            }

        } else if (couponCode.val() == '') {
            error = true;
        }

        if (error) {
            if (!$('#coupon-error').length) {
                errorElement.insertAfter(couponCode);
            }

            $('#coupon-error').text(errorText);
            couponCode.addClass('error');
        } else {
            couponCode.removeClass('error');
            $('#coupon-error').remove();
        }

        return error;
    }

    ApplyCoupon(couponCode) {

        let params = {
            action: 'coupon',
            coupon: couponCode,
            'event_id': $('#event-id').val(),
        }

        $.ajax({
            url: wpajax.wp_ajax_url,
            method: "POST",
            data: params
        }).done((response) => {
            console.log(response)

            if (response.status == 'error') {
                this.ValidateCoupon('Koden är inte giltig');
            } else {
                this.ValidateCoupon();
                let percentHtml = '<div class="col-1 meta"></div>';

                if (response.type == 'percent') {
                    percentHtml = '<div class="col-1 meta">'+
                        '<div class="discount-percent">-'+response.percent+'%</div>'+
                    '</div>';
                }

                $('.discount-row').html(''+
                    '<div class="col-7 item">' +
                        '<div class="discount">Rabatt</div>' +
                    '</div>'+
                    percentHtml +
                    '<div class="col-4 price">'+
                        '<div class="discount-price">-'+response.discount+' SEK</div>'+
                    '</div>'
                )

                $('.total-row .price').html(response.total + ' SEK');
                $('#coupon').val('');
                $('#coupon-code').val(response.coupon);
                $('#total').val(response.total.replace(/\s/g, ''));
            }
        })

    }

    ValidateForm() {

        const formValidation = $('#course-form').validate({
            success: "valid",
            errorPlacement: function(error, element) {
                if (element.attr("id") == 'consent') {
                    error.appendTo( '.consent-error' );
                } else {
                    error.insertAfter(element);
                }
            }
        }).form()

        return formValidation;
    }

    ValidatorSettings() {
        jQuery.extend(jQuery.validator.messages, {
            required: "Fältet är obligatoriskt.",
            email: "Ange en korrekt e-postadress.",
            number: "Fältet får endast innehålla siffror",
        });

        jQuery.validator.addMethod("orgRequired", jQuery.validator.methods.required,"Ange ett korrekt organisationsnummer");

        jQuery.validator.addClassRules({
            "phone" : {
                number:true,
                required: true
            },
            org: {
                number: true,
                orgRequired: true
            }
        });
    }

    SubmitForm(inputs, callback) {
        let loader = $('.gear-loader');

        loader.css('display', 'inline-block');

        let params = {
            action: 'bbo_form_submit',
            inputs: inputs
        }

        $.ajax({
            url: wpajax.wp_ajax_url,
            method: "POST",
            data: params
        }).done((response) => {
            loader.hide();

            callback(response);
        })
    }
}

export default Form;
