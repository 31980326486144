jQuery(document).ready(function() {
    jQuery('#cat_select').click(function( event ) {
        event.preventDefault();
        jQuery('#cat_list').toggleClass('show-flex hide-flex');
        jQuery('#icon_up').toggleClass('icon-chevron-up icon-chevron-down');
        jQuery('#icon_down').toggleClass('icon-chevron-down icon-chevron-up');
    });

    jQuery('.section-blog .zoom-effect-container').mouseover( function () {

        var $obj = jQuery(this).find('.image-card img');
        jQuery($obj).css('transform','scale(1.08)');
        $obj2 = jQuery(this).parent('.wrapper-image').parent().parent().find('article h2 a');
        jQuery($obj2).addClass('hover-on');
    });

   jQuery('.section-blog .zoom-effect-container').mouseout( function () {
       var $obj = jQuery(this).find('.image-card img');
       jQuery($obj).css('transform','scale(1)');
       $obj2 = jQuery(this).parent('.wrapper-image').parent().parent().find('article h2 a');
       jQuery($obj2).removeClass('hover-on');
   });

   jQuery(".section-blog article h2 a").mouseover( function () {
       var $obj2 = jQuery(this).parent().parent().parent().find('.wrapper-image .zoom-effect-container .image-card img');
       jQuery($obj2).css('transform','scale(1.08)');
   });

   jQuery(".section-blog article h2 a").mouseout( function () {
          var $obj2 = jQuery(this).parent().parent().parent().find('.wrapper-image .zoom-effect-container .image-card img');
         jQuery($obj2).css('transform','scale(1)');
   });

   jQuery(".section-featured-post .wrapper-image").mouseover( function () {
         linkOnOff( jQuery(this).closest(".col").find('article h2 a'), true);
         imgZoom(this, true);
   });
   jQuery(".section-featured-post .wrapper-image").mouseout( function () {
         linkOnOff( jQuery(this).closest(".col").find('article h2 a'), false);
         imgZoom(this, false);
   });

   jQuery(".section-featured-post article h2 a").mouseover( function () {
     imgZoom(this, true);
   });

   jQuery(".section-featured-post article h2 a").mouseout( function () {
        imgZoom(this, false);
   });
});
