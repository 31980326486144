jQuery(document).ready(function() {
      jQuery(".section-mainservice .img-wrapper").mouseover( function () {
          var $obj = jQuery(this).parent().parent().find('a');
          jQuery($obj).addClass('hover-on');
      });
      jQuery(".section-mainservice .img-wrapper").mouseout( function () {
           var $obj = jQuery(this).parent().parent().find('a');
            jQuery($obj).removeClass('hover-on');
      });

      var $obj = jQuery(".section-single-content img").parent();

      if ( $obj.is("p") ) {
           jQuery($obj).css("text-align","center");
      }

});
