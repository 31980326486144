function startVideoSound( videoID ) {

    var vid = document.getElementById( videoID );
    vid.controls = true;
    vid.muted = false;

    var playBtn = document.getElementById("playBtn-"+videoID);
    playBtn.parentNode.removeChild(playBtn);

}
